import React from 'react';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class OkinawanGojuRyusManWithAMission extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Sensei Graham Ravey, Okinawan Goju Ryu's Man with a Mission - Australasian Fighting Arts
                    Magazine</h1>
                <StaticImage src="../../images/manonamission/ResizedImage600868-7883761.jpg"
                             alt="Okinawan Goju Ryu's Man with a Mission Page 1"/>
                <StaticImage src="../../images/manonamission/ResizedImage600868-2854495.jpg"
                             alt="Okinawan Goju Ryu's Man with a Mission Page 2"/>
                <StaticImage src="../../images/manonamission/ResizedImage600868-4098280.jpg"
                             alt="Okinawan Goju Ryu's Man with a Mission Page 3"/>
                <StaticImage src="../../images/manonamission/ResizedImage600868-3825816.jpg"
                             alt="Okinawan Goju Ryu's Man with a Mission Page 4"/>
            </Layout>);
    }
}

export default OkinawanGojuRyusManWithAMission;
